export const educationList = [
	{
		title: 'Меньше теории, больше практики',
		desc: 'Вместо скучных лекций ты прокачаешь игровую механику, стратегию и командное взаимодействие.',
		bg: '/education/education/Edu-Bg-1.webp',
		bgSmall: '/education/education/Edu-Bg-1-small.webp',
	},
	{
		title: 'Крутые бонусы',
		desc:
			'Участвуй в эксклюзивных ивентах для учеников школы Counter Strike: выигрывай подарки, скидки на обучение, скины и многое другое!',
		bg: '/education/education/Edu-Bg-2.webp',
		bgSmall: '/education/education/Edu-Bg-2-small.webp',
	},
	{
		title: 'Занимайся, когда есть время',
		desc: 'Составим удобный индивидуальный график тренировок: тренер подстроится под тебя, а не наоборот.',
		bg: '/education/education/Edu-Bg-3.webp',
		bgSmall: '/education/education/Edu-Bg-3-small.webp',
	},
	{
		title: 'Персональная помощь тренера',
		desc:
			'Тренер научит тебя управлять своими эмоциями и реагировать на стрессовые ситуации. Во время обучения ты всегда можешь рассчитывать на его поддержку!',
		bg: '/education/education/Edu-Bg-4.webp',
		bgSmall: '/education/education/Edu-Bg-4-small.webp',
	},
	{
		title: 'Отправляем статистику лучших учеников рекрутам из про-команд',
		desc: 'Если горишь мечтой попасть в киберспорт и готов выкладываться на 200% — докажи на деле, а мы поможем!',
		bg: '/education/education/Edu-Bg-5.webp',
		bgSmall: '/education/education/Edu-Bg-5-small.webp',
	},
	{
		title: 'Новые знакомства',
		desc:
			'Делись лучшими моментами, смотри турнирные матчи, общайся и обменивайся опытом с единомышленниками и друзьями на нашем сервере Discord.',
		bg: '/education/education/Edu-Bg-6.webp',
		bgSmall: '/education/education/Edu-Bg-6-small.webp',
	},
];
