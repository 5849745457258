import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './when.module.scss';

const When = ({ onClick }) => {
	const intl = useIntl();
	return (
		<section className={styles.when}>
			<div className={styles.when__title}>Когда начнем?</div>
			<img src="/education/When-Img.webp" alt="Когда начнем?" />
			<div className={styles.when__action}>
				<button
					className={[styles.button, styles.button_big].join(' ')}
					onClick={() => {
						mixPanelService.sendEvent({
							event: 'Academy Landing / Click Buy Scroll Button',
							properties: { location: 'last screen' },
						});
						onClick();
					}}
				>
					Начать обучение бесплатно
				</button>
			</div>
		</section>
	);
};

export default When;
