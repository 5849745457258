import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../../services/mixPanelService';
import * as styles from './promo.module.scss';

const Promo = ({ onClick }) => {
	const intl = useIntl();
	return (
		<div className={styles.promo}>
			<div className={styles.promo__title}>
				<span className={styles.promo__title__main}>групповые тренировки<br />с опытными геймерами</span>
				{/*<br />
				<div className={styles.promo__title__academy}>
					<div>побеждать</div>
					<div>чаще</div>
				</div>*/}
				{/*<div className={styles.promo__title__add}>
					<div>на обучающих<br />играх</div>
					<div>с опытными<br />тренерами</div>
				</div>*/}
				<div className={styles.promo__title__mobile}>
					групповые тренировки<br />с опытными геймерами
				</div>
			</div>
			{/*<div className={styles.promo__desc}>
				Работаем по методике
				<br /> киберспортсменов, которая
				<br /> увеличит твой винрейт за 2 недели!
			</div>*/}
			<div className={styles.promo__img}>
				<img src="/education/Promo-Img-New-3.png" alt="прокачай скилл и начни побеждать чаще" />
			</div>
			<div className={styles.promo__img_mobile}>
				<img src="/education/Promo-Img-New-3-Mobile.webp" alt="прокачай скилл и начни побеждать чаще" />
			</div>
			<div className={styles.promo__action}>
				<button
					className={styles.button}
					onClick={() => {
						mixPanelService.sendEvent({
							event: 'Academy Landing / Click Buy Scroll Button',
							properties: { location: '1st screen' },
						});
						onClick();
					}}
				>
					Начать обучение бесплатно
				</button>
				{/*<div className={styles.sale}>–31%</div>*/}
			</div>
			<div className={styles.promo__disclaimer}>
				Нажимай на кнопку и запишись на <span>бесплатный</span> тестовый урок с тренером, а также получи доступ к нашему чату с учениками
			</div>
		</div>
	);
};

export default Promo;
