export const priceList = [
	{
		title: '🤝 Знакомство с тренером',
		desc: 'Познакомься со своим наставником поближе, чтобы совместная работа приносила не только пользу,\nно и радость',
	},
	{
		title: '📚 Тест на знания и скилл',
		desc: 'Узнай свои слабые и сильные стороны, чтобы тренировки приносили максимальную пользу',
	},
	{
		title: '📈 Анализ игрового профиля',
		desc: 'Вместе с тренером изучите твою статистику и определите, над чем работать',
	},
	{
		title: '🎁 Топовые подарки',
		desc: 'Дарим подписку на Scope.gg на 7 дней и самые актуальные гайды по настройке твоего ПК и CS2',
	},
	{
		title: '🎉 Скидка на обучение',
		desc: 'Подарим скидку на обучение и забронируем персональное место',
	},
	{
		title: 'Доступ в чат',
		desc: 'Познакомься с другими игроками, следи за новостями и акциями в нашем чате в TG',
		icon: '/education/Price-Tg.svg',
	},
];
